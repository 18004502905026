import LandingPage from "views/LandingPage/LandingPage.jsx";
import ThankYouPage from "views/ThankYouPage/ThankYouPage.jsx";
import SponsorsPage from "views/SponsorsPage/SponsorsPage.jsx";
import TrailblazersPage from "views/TrailblazersPage/TrailblazersPage";

var indexRoutes = [
  { path: "/trailblazers", name: "TrailblazersPage", component: TrailblazersPage},
  { path: "/sponsors", name: "SponsorsPage", component: SponsorsPage },
  { path: "/thank-you", name: "ThankYouPage", component: ThankYouPage },
  { path: "/", name: "LandingPage", component: LandingPage }
];

export default indexRoutes;
