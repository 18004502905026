import React from "react";

//@material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import sponsorsPageStyle from "assets/jss/views/sponsorsPageStyle";
// core components
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer";
// sections of this page
import SectionSponsors from "views/SponsorsPage/Sections/SectionSponsors.jsx";
// nodejs libraries
import classNames from "classnames";

class SponsorsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          links={<HeaderLinks dropdownHoverColor="warning" />}
          brand="THE LINE"
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "warning"
          }}
        />
        <Parallax image={require("assets/img/banner_sponsors.jpg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} md={12} sm={12}>
                <h1 className={classes.title}>Unsere Sponsoren</h1>
                <h1>Dank euch wird THE LINE Realität</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
           <SectionSponsors/>
          </div>
        </div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/#privacy"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzberstimmungen
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/latest-news/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Logbuch
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a href="http://www.breaking-borders.eu" target="_blank" rel="noopener noreferrer">
                  BREAKING BORDERS (UG)
                </a>{" "}
                for a better world.
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(sponsorsPageStyle)(SponsorsPage);
