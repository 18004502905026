import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import thankYouPageStyle from "assets/jss/views/thankYouPageStyle";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Favorite from "@material-ui/icons/Favorite";

const dashboardRoutes = [];

class ThankYouPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="THE LINE"
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "warning"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/banner_thankyou.jpg")}
          filter="dark"
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} md={12} sm={12}>
                <h1 className={classes.title}>
                  DANKE - Ein Wort, das alles sagt
                </h1>
                <h1>Jeder Kilometer zählt - danke für deine Unterstützung</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/#privacy"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzberstimmungen
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/latest-news/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Logbuch
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a href="http://www.breaking-borders.eu" target="_blank" rel="noopener noreferrer">
                  BREAKING BORDERS (UG)
                </a>{" "}
                for a better world.
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(thankYouPageStyle)(ThankYouPage);
