import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ScrollableAnchor from "react-scrollable-anchor";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/views/landingPageStyle.jsx";

// Sections for this page
import SectionWhatIsTheLine from "./Sections/SectionWhatIsTheLine.jsx";
import SectionTrailblazerSponsoren from "./Sections/SectionTrailblazerSponsoren.jsx";
import SectionTestimonials from "./Sections/SectionTestimonials";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          links={<HeaderLinks dropdownHoverColor="warning" />}
          brand="THE LINE"
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "warning"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/banner.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} md={6} sm={6}>
                <h1 className={classes.title}>THE LINE</h1>
                <h1>Dein Statement für Verbundenheit</h1>
                <br />
                <Button
                  color="warning"
                  size="lg"
                  href="#start"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-angle-down" />
                  Ich will mehr wissen
                </Button>
              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <div className={classes.iframeContainer}>
                  <iframe
                    title="THE LINE"
                    height="315"

                    src="https://www.youtube-nocookie.com/embed/pT0_qjb_VdI?autoplay=1&controls=0&loop=1"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ScrollableAnchor id={"start"}>
              <SectionWhatIsTheLine />
            </ScrollableAnchor>
            <SectionTrailblazerSponsoren />
            <SectionTestimonials />
            <br />
          </div>
        </div>
        <Footer
          theme="white"
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Impressum
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/imprint/#privacy"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzberstimmungen
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="http://blog.breaking-borders.eu/latest-news/"
                      className={classes.block}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Logbuch
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a href="http://www.breaking-borders.eu" target="_blank" rel="noopener noreferrer">
                  BREAKING BORDERS (UG)
                </a>{" "}
                for a better world.
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
