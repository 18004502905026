import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Muted from "components/Typography/Muted.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Button from "components/CustomButtons/Button.jsx";

import cardSponsor1Square from "assets/img/faces/card-sponsor1-square.png";
import cardTrailblazer1Square from "assets/img/faces/card-trailblazer1-square.png";

import trailblazerSpronsorenStyle from "assets/jss/views/landingPageSections/trailblazerSponsorenStyle";
import classNames from "classnames";

const style = {
  ...trailblazerSpronsorenStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

class SectionTrailblazerSponsoren extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={10}>
              <h2 className={classes.title}>Gute Gründe</h2>
              <h4 className={classes.description}>
                Ohne dich bleibt THE LINE nur eine Vision. Mit dir wird THE LINE
                Realität. Du bist Teil einer außergewöhnlichen Aktion, die es
                bisher noch nie gegeben hat.
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Unterstütze uns</h3>
              <Card profile plain className={classes.card3}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <img src={cardSponsor1Square} alt="Sponsor #1" />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardSponsor1Square})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Sponsor #1</h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>
                          Susanne Schleyer
                        </h6>
                      </Muted>
                      <p className={classes.description}>
                        "Eine super Idee von BREAKING BORDERS, die ich gern
                        unterstütze..."
                      </p>
                    </CardBody>
                    <CardFooter plain className={classes.justifyContentCenter}>
                      <p>
                        Werde Sponsor wie Susanne. Sie hat den Ersten von
                        insgesamt 40.000 Kilometern gesponsert. Als Sponsor
                        unterstützt du das Projekt, indem du mindestens einen
                        Kilometer für nur 10,00 Euro sponserst.{" "}
                      </p>
                    </CardFooter>
                    <Button
                      href="/sponsors"
                      color="warning"
                      className={"classes.navButton"}
                      round
                    >
                      Alle Sponsoren
                    </Button>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Sei dabei</h3>
              <Card profile plain className={classes.card3}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <img src={cardTrailblazer1Square} alt="Trailblazer #1" />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardTrailblazer1Square})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Trailblazer #1</h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>Irina Heilmann</h6>
                      </Muted>
                      <p className={classes.description}>
                        "I would walk 500 miles and I would walk 500 more,..."
                      </p>
                    </CardBody>
                    <CardFooter plain className={classes.justifyContentCenter}>
                      <p>
                        Werde Trailblazer wie Irina. Als Trailblazer
                        (Wegbereiter) zeichnest du selbst durch deine Bewegung
                        ein Stück der Linie. Ob du läufst, schwimmst, fliegst
                        oder fährst spielt dabei keine Rolle.
                      </p>
                    </CardFooter>
                    <Button
                      href="/trailblazers"
                      color="warning"
                      className={"classes.navButton"}
                      round
                    >
                      Alle Trailblazer
                    </Button>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Gute Gründe für Sponsoren</h3>
              <p className={classes.reason}>
                Du sorgst für Chancengleichheit, weil alle Trailblazer kostenlos
                mitmachen können. Du ermöglichst dir und den Trailblazern ein
                unvergessliches Erlebnis. Deine finanzielle Hilfe ist dein
                eigenes, persönliches Statement für Verbundenheit. Gemeinsam
                sind wir stark und handeln. Sind 40.000 km erreicht, spenden wir
                15.000 Euro an die Kinderstiftung Bodensee.
              </p>
              <p className={classes.reason}>
                Unsere Bankverbindung lautet:<br/>
                Sparkasse Bodensee<br/>
                Verwendungszweck: Kilometersponsoring<br/>
                IBAN DE21 6905 0001 0026 2793 80<br/>
                BIC SOLADES1KNZ<br/>
              </p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Gute Gründe für Trailblazer</h3>
              <p className={classes.reason}>
                Du setzt ein direktes Zeichen für Verbundenheit durch deine
                Bewegung. Dein Stück der Linie wird mit den Linien aller
                Trailblazer weltweit verbunden. Du bist Wegbereiter für eine
                Welt der Vielfalt. Du ermöglichst dir und den Sponsoren ein
                unvergessliches Erlebnis. Gemeinsam erschaffen wir eine Linie um
                die Welt als symbolisches Band für Verbundenheit.
              </p>
              <p className={classes.reason}><em><b>
                Danke für die vielen Bewerbungen als Trailblazer. Aktuell kannst du dich nicht mehr
                über unsere Website als Trailblazer bewerben. Hab etwas Geduld, bald gehts richtig los.</b></em>
              </p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Button
                href="https://donorbox.org/theline"
                color="warning"
                className={classNames(classes.navButton, "custom-dbox-popup")}
                round
              >
                Jetzt Sponsor werden
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Button
                href="mailto:join@breaking-borders.eu?subject=Ich%20bewerbe%20mich%20als%20Trailblazer&body=Erzähl%20uns,%20warum%20du%20als%20Trailblazer%20THE%20LINE%20unterstützen%20möchtest%20und%20welches%20Stück%20von%20THE%20LINE%20du%20gern%20gehen%20möchtest."
                color="warning"
                className={classNames(classes.navButton, "custom-dbox-popup")}
                round
                disabled={true}
              >
                Jetzt als Trailblazer bewerben
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Werde Teil von THE LINE</h3>
              <p className={classes.reason}>
                Sponsere mindestens einen Kilometer für 10 Euro.
              </p>
              <h3 className={classes.reason}>Deine Belohnung</h3>
              <span>
                <p className={classes.reason}>
                  Du wirst als Sponsor auf der Website genannt, wenn du
                  einverstanden bist.
                </p>
              </span>
              <div>
                <NavPills
                  horizontal={{
                    tabsGrid: { xs: 12, sm: 4, md: 5 },
                    contentGrid: { xs: 12, sm: 8, md: 7 }
                  }}
                  color="warning"
                  tabs={[
                    {
                      tabButton: "Ab 1 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst von uns ein Dankeschön per E-Mail.
                          </p>
                          <p className={classes.reason}>
                            Du wirst als Sponsor hier auf dieser Seite genannt,
                            wenn du einverstanden bist.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "Ab 5 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst einen THE LINE Aufkleber zugeschickt.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "10 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst einen Druck vom ERSTEN Bild, das wir
                            Gründer durch Bewegung zeichnen.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "25 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst ein THE LINE Shirt und einen Druck vom
                            ERSTEN Bild, das wir Gründer durch Bewegung
                            zeichnen.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "50 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst ein THE LINE Shirt und einen von uns
                            signierten Druck vom ERSTEN Bild, das wir Gründer
                            durch Bewegung zeichnen.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "100 Kilometer",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst ein THE LINE Shirt und das ERSTE Bild
                            auf Leinwand 20x30, das wir Gründer durch Bewegung
                            zeichnen und für dich persönlich signieren.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "1000 Kilometer (Limitiert)",
                      tabContent: (
                        <span>
                          <p className={classes.reason}>
                            Du bekommst ein THE LINE Shirt und das ERSTE Bild,
                            das wir Gründer durch Bewegung zeichnen auf einem
                            handgefertigten Keilrahmen 20x30.
                          </p>
                          <p className={classes.reason}>
                            Die Bilder sind nummeriert von 1 - 10.
                          </p>
                          <p className={classes.reason}>
                            Mit persönlicher Widmung von uns Gründern für dich
                            signiert wird jedes zu einem Unikat.
                          </p>
                        </span>
                      )
                    }
                  ]}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Werde Teil von THE LINE</h3>
              <p className={classes.reason}>Melde dich als Trailblazer an.</p>
              <h3 className={classes.reason}>Deine Belohnung</h3>
              <p className={classes.reason}>
                Den ersten 15 Trailblazern schenken wir ein T-Shirt mit ihrer
                ganz persönlichen Startnummer. Die Reihenfolge ergibt sich aus
                deiner Anmeldung.
              </p>
              <p className={classes.reason}>
                Schaffst du es unter die ersten 25 Trailblazer, nennen wir dich
                auf der Website, wenn du einverstanden bist. Alle anderen
                Trailblazer werden erst während THE LINE um die Welt geht
                bekannt.
              </p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Was passiert mit meinem Geld?</h3>
              <p className={classes.reason}>
                THE LINE ist ein soziales Projekt der BREAKING BORDERS UG
                (haftungsbeschränkt). Als UG sind wir Umsatzsteuerpflichtig.
                Abzüglich Steuern verwenden wir alle Einnahmen aus dem
                Sponsoring zur Realisierung des Projektes THE LINE. Wir, die
                Gründer, Birgit und Mark Joachim, sind ehrenamtlich für die UG
                und THE LINE tätig. Erreichen wir gemeinsam das Sponsoringziel
                von 40.000 Kilometern, spenden wir 15.000 Euro an die
                Kinderstiftung Bodensee.
              </p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.title}>Wie funktioniert das?</h3>
              <p className={classes.reason}>
                Ganz einfach in dem du dich bewegst. In deinem Alltag bewegst du
                dich jeden Tag von einem Ort zum Anderen. Das sind mal kurze
                oder mal lange Wege. Manchmal fährst du, manchmal läufst du oder
                fliegst. Als Trailblazer tust du genau das, du bewegst dich. Der
                einzige Unterschied ist, dass du deine Bewegung mit unserer
                Hilfe zeichnest. Dein Stift ist eine App auf deinem Smartphone.
                Die Welt ist dein Papier auf dem du zeichnest. Sichtbar wird
                deine Bewegung als Linie auf unserer Plattform. Deine Linie und
                die Linien aller Trailblazer ergeben zusammen eine Linie um die
                ganze Welt.
              </p>
              <p className={classes.reason}>
                THE LINE startet in 2019 ab Friedrichshafen in die Welt.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(SectionTrailblazerSponsoren);
