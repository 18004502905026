import React from "react";
// react component for creating beautiful carousel
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.jsx";

import testimonalBackground from "assets/img/banner_testimonials.jpg";
import cardTestimonial1Square from "assets/img/faces/card-testimonial1-square.png";
import cardTestimonial2Square from "assets/img/faces/card-testimonial2-square.png";
import cardTestimonial3Square from "assets/img/faces/card-testimonial3-square.png";

function SectionTestimonials({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div
        className={`${classes.testimonials} ${classes.sectionImage}`}
        style={{ backgroundImage: `url(${testimonalBackground})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>Stimmen</h2>
              <h5 className={classes.description}>
                Das sagen die ersten Sponsoren und Trailblazer über THE LINE
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    "Great initiative which deserves many more supporters!"<br/><br/>
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Christiane</h4>
                  <h6 className={classes.cardCategory}>&nbsp;</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={cardTestimonial1Square} alt="testimonial1" />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    "Definitiv kann der Einzelne viel erreichen. Gut, dass sich das in der Gemeinschaft multiplizieren lässt.“
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Jutta Metzler</h4>
                  <h6 className={classes.cardCategory}>&nbsp;</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={cardTestimonial3Square} alt="..." />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    "THE LINE ist ein großartiges Projekt. Das richtige Zeichen
                    für globale Verbundenheit in der heutigen Zeit."
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>
                    Anne Claire Schröder-Rose
                  </h4>
                  <h6 className={classes.cardCategory}>Malerin</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={cardTestimonial2Square} alt="testimonial2" />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(testimonialsStyle)(SectionTestimonials);
