import { title } from "assets/jss/material-kit-pro-react.jsx";
import imagesStyles from "../../material-kit-pro-react/imagesStyles";

const whatIsTheLineStyle = {
  ...imagesStyles,
  section: {
    paddingBottom: "0px",
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#555"
  },
  quoteText: {
    fontSize: "1.5rem !important",
    color: "#555"
  },
  feature: {
    padding: "80px 0"
  },
  infoArea: {
    maxWidth: "none",
    margin: "0 auto",
    padding: "10px 0 0px"
  }
};

export default whatIsTheLineStyle;
