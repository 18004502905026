import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import whatIsTheLineStyle from "assets/jss/views/landingPageSections/whatIsTheLineStyle.jsx";
import Quote from "components/Typography/Quote";
import Earth from "@material-ui/icons/Language";
import footprint from "assets/img/footprint.jpg";
import Star from "@material-ui/icons/Star";
import WatchLater from "@material-ui/icons/WatchLater";
import People from "@material-ui/icons/People";
import Card from "components/Card/Card";

class SectionWhatIsTheLine extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={10}>
            <h2 className={classes.title}>
              Menschen verbinden und Grenzen überwinden - dafür steht THE LINE.
            </h2>
            <br />
            <Quote
              textClassName={classes.quoteText}
              text="“Wir wollen in einer Welt leben, in der nicht die Furcht voreinander sondern die Sorge umeinander im Vordergrund steht.”"
              author="Birgit und Mark Joachim, Gründer"
            />
            <br />
            <h5 className={classes.description}>
              THE LINE setzt ein sichtbares Zeichen gegen Abgrenzung,
              Protektionismus und Fremdenfeindlichkeit.
            </h5>
            <h5 className={classes.description}>
              Das Zeichen ist eine Linie um die Welt. Das besondere daran ist,
              dass die Linie durch deine Bewegung entsteht. Wir bewegen uns
              jeden Tag zu Fuß oder auf Rädern von einem Ort zum Anderen. Für
              THE LINE bewegen sich tausende Menschen Stück für Stück einmal um
              die Welt. Jede einzelne dieser Bewegung wird als Linie sichtbar.
              So entsteht durch Bewegung eine durchgängig gezeichnete Linie um
              die Welt als symbolisches Band für Verbundenheit.
            </h5>
          </GridItem>
        </GridContainer>
        <div className={classes.feature}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <img
                  src={footprint}
                  alt="Footprint"
                  className={classes.imgCard}
                />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h3 className={classes.description}>
                THE LINE ist das erste und größte Statement der Welt, das durch
                menschliche Bewegung entsteht.
              </h3>
              <InfoArea
                className={classes.infoArea}
                icon={Earth}
                title="THE LINE ist 40.000 km lang"
                description="“Mindestens. Wir werden alle gemeinsam jedoch eine bedeutend längere Strecke zurücklegen, da wir uns im Zick-Zack-Kurs um die Welt bewegen.“ Birgit"
                iconColor="warning"
              />
              <InfoArea
                className={classes.infoArea}
                icon={Star}
                title="THE LINE ist neu und einzigartig"
                description="“Uns ist bisher nicht bekannt, dass es schon einmal ein solches Projekt gegeben hat. Für die einen ist es ein Kunstprojekt und für andere ein soziales. Für uns ist es beides. Habt ihr schon von so einem Projekt gehört oder mitgemacht?“ Mark"
                iconColor="warning"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="THE LINE ist eine einmalige Chance"
                description="“Das ist in der Tat so. Wir machen das nur ein einziges Mal. Die Welt ist groß, THE LINE kann nicht überall durchkommen, aber sie ist da wo du bist, wenn du mitmachst.“ Birgit"
                iconColor="warning"
              />
              <InfoArea
                className={classes.infoArea}
                icon={People}
                title="THE LINE will gemeinsam Großes erreichen"
                description="“Wir stehen vor Herausforderungen, die uns alle angehen. Aber wie wollen wir die lösen, wenn wir uns einander nicht mal unvoreingenommen begegnen können. THE LINE geht einmal um unsere Welt. Größer können wir gemeinsam das Statement nicht machen.“ Birgit und Mark"
                iconColor="warning"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(whatIsTheLineStyle)(SectionWhatIsTheLine);
