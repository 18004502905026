import React from "react";
import axios from "axios";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";


import { CircleLoader } from 'react-spinners';
import { css } from '@emotion/core';

// @materail-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sponsorsStyle from "assets/jss/views/sponsorsPageSections/sponsorsStyle.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class SectionSponsors extends React.Component {
  state = {
    sponsors: [],
    isLoading: true
  };

  componentDidMount() {
    axios
      .get(
        "https://kpeec4w2lh.execute-api.eu-central-1.amazonaws.com/prod/sponsors"
      )
      .then(response => {
        this.setState({ sponsors: response.data, isLoading: false });
      });
  }

  render() {
    const { classes } = this.props;

    const sponsors = this.state.sponsors.map(sponsor => {
      return (
        <GridItem md={3} sm={3} key={sponsor.rank}>
          <Card profile plain>
            <CardHeader image>
              <img
                src={
                  "https://s3.eu-central-1.amazonaws.com/betheline/assets/images/" +
                  sponsor.sponsorId +
                  ".jpg"
                }
                alt={sponsor.title}
                className={classes.img}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{sponsor.title}</h4>
              <h5 className={classes.textMuted}>{sponsor.subTitle}</h5>
              <p className={classes.cardDescription}>{sponsor.statement}</p>
            </CardBody>
            <CardFooter className={classes.justifyContent}>
              {sponsor.kilometers > 0 &&
                <p>{sponsor.kilometers} Kilometer</p>
              }
            </CardFooter>
          </Card>
        </GridItem>
      );
    });

    return (
      <div className={classes.sponsors}>
        <CircleLoader
          css={override}
          sizeUnit={"px"}
          size={100}
          color="orange"
          loading={this.state.isLoading}
        />
        <GridContainer>{sponsors}</GridContainer>
      </div>
    );
  }
}

export default withStyles(sponsorsStyle)(SectionSponsors);
